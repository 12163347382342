@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  @variants responsive {
    .text-shadow {
      text-shadow: 2px 2px 4px black;
    }
    .text-shadow-none {
      text-shadow: none;
    }
  }
}
