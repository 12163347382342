/* ********************************** */
/*    Scrubstr Record 5 Face Rating   */
/* ********************************** */
.scrubstr-five-face-rating-container {
  font-size: 2em;
}

.scrubstr-five-face-rating-container ul.scrubstr-five-face-rating {
  padding: 0 !important;
  list-style: none;
  line-height: 1em;
}

.scrubstr-five-face-rating-container li.scrubstr-face-rating-face {
  position: relative;
  display: inline-block;
  height: 1em;
  width: 1em;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.scrubstr-five-face-rating-container li.scrubstr-face-rating-face:not(:last-of-type) {
  margin-right: 0.2em;
}

.scrubstr-five-face-rating-container .scrubstr-five-face-rating li.scrubstr-face-rating-face.scrubstr-filled-face > div,
.scrubstr-five-face-rating-container
  .scrubstr-five-face-rating
  li.scrubstr-face-rating-face.scrubstr-half-filled-face
  > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center left;
  background-size: cover;
}

.scrubstr-five-face-rating-container
  .scrubstr-five-face-rating
  li.scrubstr-face-rating-face.scrubstr-half-filled-face
  > div {
  right: 50%;
}

@media screen and (max-width: 440px) {
  .scrubstr-five-face-rating-container li.scrubstr-face-rating-face {
    height: 10vw;
    width: 10vw;
  }

  .scrubstr-five-face-rating-container li.scrubstr-face-rating-face:not(:last-of-type) {
    margin-right: 0.2vw;
  }
}
